<template>
  <div class="slider-swipe" :auto="0" :show-indicators="false">
    <div v-if="isFinish" class="float-view"></div>
    <van-swipe 
      :duration="durationTime"
      height="64"
      ref="swipe"
      class="my-swipe" 
      :class="state === 'GOING_SHIPMENT' ? 'swipe-radius' : ''"
      indicator-color="white" 
      :loop="false" 
      :show-indicators="false"
      @change="handleChange">
      <van-swipe-item :style="{backgroundColor: slideAfterColor}">完成</van-swipe-item>
      <van-swipe-item class="item2" :style="{backgroundColor: slideBeforeColor}">
        <img src="@/assets/arrow-right.png" alt="">
        <slot></slot>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
export default {
  props: {
    state: {
      type: String,
      default: '',
    },
    slideBeforeColor: {
      type: String,
      default: '#2CA568'
    },
    slideAfterColor: {
      type: String,
      default: '#EE690D'
    }
  },
  data() {
    return {
      timer: null,
      durationTime: 0,
      isFinish: false
    }
  },
  created() {
      this.init()
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.durationTime = 500
    }, 100);
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.isFinish = false
        this.$refs.swipe.swipeTo(1)
      })
      
    },
    handleChange(index) {
      if (index == 0) {
        // 完成
        this.isFinish = true
        this.$emit("slidFinish")
      }
      console.log(index)
    }
  }
}
</script>

<style scoped lang="scss">
.slider-swipe {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  .float-view {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 64px;
  text-align: center;
  // border-radius: 8px;
}
.swipe-radius {
  border-radius: 8px;
}

.item2 {
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  img {
    width: 24px;
    height: 24px;
  }
}

</style>
