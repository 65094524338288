<template>
  <div style="position: relative;">
    <div class="map-float-content">
      <div class="content-bottom" @click="$emit('sureClick', 1)" v-if="isUp">
        <OrderInfoPrice :price='info.estimated_money'></OrderInfoPrice>
      </div>
      <div v-else>
        <OrderDetail :detail="info" @anotherOrder="addOrder" @orderDetail="$emit('sureClick', 5)"></OrderDetail>
      </div>
      <div class="content-top top-driver">
        <OrderStateDes :title="info.order_status === 9 ? '已到达目的地' : '正在前往目的地'"
                       :showTips="info.order_status === 9 ? true : false">
          <slot>
            <div v-if="info.order_status === 9">
              <span>司机已到达卸货点，请您尽快联系司机装货</span>
            </div>
            <div v-else>
              <span>司机正在前往<span class="span-orange">{{ info.uninstall_name }}</span>途中</span>
            </div>

          </slot>
        </OrderStateDes>
        <DriverInfo :detail="info"></DriverInfo>
        <OrderActionItem :list="actionList" :unReadNum="messageNumber" @clickItem="handleItem"></OrderActionItem>
      </div>
      <OrderOutIn :isUp="isUp" @clickArrow="handleArrow"></OrderOutIn>
    </div>
    <AlterOrderEndLocation v-if="isShowAlter" :detail="info" :location="selectLocation" @clickCancel="endLocationCancel"
                           @clickSure="endLocationSure"></AlterOrderEndLocation>
    <div class="place-search">
      <SearchPlace v-if="isShowSearch" :info="placeInfo" @clickCancel="handleCancel"
                   @clickSure="clickSure"></SearchPlace>

    </div>
  </div>
</template>

<script>
import OrderInfoPrice from './orderInfo/order-info-price.vue'
import OrderActionItem from './orderInfo/order-action-item.vue'
import DriverInfo from './orderInfo/driver-info.vue'
import OrderStateDes from './orderInfo/order-state-des.vue'
import {ORDER_ACTION_LIST2} from "../model/stateModel"
import SearchPlace from "./orderInfo/search-place.vue"
import AlterOrderEndLocation from './orderInfo/alter-order-end-location.vue'
import OrderOutIn from './orderInfo/order-out-in.vue'
import OrderDetail from './orderInfo/order-detail.vue'

export default {
  props: ['info', 'messageNumber'],
  components: {
    OrderInfoPrice,
    OrderActionItem,
    DriverInfo,
    OrderStateDes,
    SearchPlace,
    AlterOrderEndLocation,
    OrderDetail,
    OrderOutIn
  },
  data() {
    return {
      actionList: ORDER_ACTION_LIST2,
      isShowSearch: false,
      isShowAlter: false,
      selectLocation: '',
      isUp: true,
    }
  },
  computed: {
    placeInfo() {
      console.log('info====', JSON.stringify(this.info))
      let lat = this.info.uninstall_latitude ? this.info.uninstall_latitude : ''
      let lng = this.info.uninstall_longitude ? this.info.uninstall_longitude : ''
      return {
        name: this.info.uninstall_name,
        position: [lng, lat]
      }
    }
  },
  created() {

  },
  mounted() {
    this.isShowAlter =this.$store.state.$alertEndPlace
    if(this.isShowAlter){
      this.selectLocation =this.$store.state.$alertItem
    }
    this.changeActionList()
  },
  methods: {
    changeActionList() {
      if (this.info.order_status === 9) {
        this.actionList.shift()
      }
    },
    handleArrow() {
      this.isUp = !this.isUp
    },
    handleItem(val) {
      if (val.value === 5) {
        // 修改终点
        //this.isShowSearch = true
        const info = this.info
        const location = JSON.stringify({'longitude': info.uninstall_longitude, 'latitude': info.uninstall_latitude})
        const address = JSON.stringify({
          "address": info.uninstall_name,
          "position": [info.uninstall_longitude, info.uninstall_latitude],
          "fullAddress": info.uninstall_address
        })
        let params = {
          time: (new Date()).getTime(),
          location: encodeURIComponent(location),
          address:address,
          from: 'changeEndPlace',
          type: 'end'
        }
        this.$router.push({name: 'chooseLocation', query: params})
        return
      }
      this.$emit('sureClick', 3, val)
    },
    handleCancel() {
      this.isShowSearch = false
    },
    clickSure(item) {
      this.isShowSearch = false
      console.log('select:', item)
      this.isShowAlter = true
      this.selectLocation = item
    },
    endLocationCancel() {
      this.isShowAlter = false
      console.log('关闭。。。。')
    },
    endLocationSure() {
      this.isShowAlter = false
      console.log('确认修改。。。')
      this.$emit('sureClick', 4, this.selectLocation)
    },
    addOrder() {
      this.$emit('sureClick', 3, {value: 7})
    }
  }
}
</script>

<style scoped lang="scss">
.top-driver {
  padding: 15px 0;
}

.place-search {

}

</style>
