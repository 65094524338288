import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    $tickets:'',
    $alertEndPlace:false,
    $alertItem:{},
  },
  mutations: {},
  actions: {},
  modules: {},
});
