// 公共js类  封装一些常用的基础函数方法
// 应用的基础信息
import moment from "moment"


// 获取基础接口请求的参数包装
export function getRequestBaseParam(requestData) {

    // 添加必传参数的数据
    let originalData = {}
    //todo 添加基础数据

    // 业务参数 如果没有业务参数则传{}空对象
    if (typeof requestData == "object") {
        for (let val in requestData) {
            if (!requestData.hasOwnProperty(val)) continue;
            originalData[val] = requestData[val]
        }
    }

    return originalData

}

//获取是今天还是明天
export function getTimeDesc(time) {
    let today = moment().startOf('day');
    let tomorrow = moment().add(1, 'days').startOf('day');
    let theDayAfterTomorrow = moment().add(1, 'days').startOf('day');
    if (moment.unix(time).isSame(today, 'd')) {
        return '今天'
    }
    if (moment.unix(time).isSame(tomorrow, 'd')) {
        return '明天'
    }
    if (moment.unix(time).isSame(theDayAfterTomorrow, 'd')) {
        return '后天'
    }
    return moment.unix(time).format('YYYY年MM月DD日')
}

//根据数组的值获取key
export  function findKey (data,value, compare = (a, b) => a === b) {
    return Object.keys(data).find(k => compare(data[k], value))
}

export default {
    getRequestBaseParam, 		//获取基础接口请求的参数包装
    getTimeDesc	,				//获取是今天还是明天
    findKey,                    //根据数组的值获取key
}
