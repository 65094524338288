<template>
	<div @click="$emit('clickItem')" class="jgy-type-button" :style="{backgroundImage: item.state === 0 ? '' : bgImg}" :class="item.state === 0 ? 'button-default' : 'button-select'">
		{{item.name}}
	</div>
</template>

<script>
	export default {
		props:['item'],
		data() {
			return {
        bgImg: 'url(' + require('@/assets/checked_bg2.png') + ')'
			}
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	.jgy-type-button {
		font-size: 12px;
		letter-spacing: 2px;
		text-align: center;	
    margin-left: 10px;
    // box-sizing: border-box;
    letter-spacing: 0px;
	}
	.button-select {
		background-size: 100% auto;
		width: 64px;
		height: 32px;
		line-height: 32px;
	}
	.button-default {
		border: 1px solid #DDDDDD;
		color: #666;
		width: 62px;
		height: 30px;
		border-radius: 4px;
		line-height: 30px;
	}
	
</style>
