function isAndroid() {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOS) {
    return true
  } else {
    return false
  }
}

export {
  isAndroid
}