<template>
  <div class="navigation">
    <div class="navigation-title">
      <p>{{title ? title : ''}}</p>
    </div>
    <div class="right-btn" @click="$emit('clickItinerary', info)" v-if="info">
      我的行程{{ info.total_count ? `(${info.total_count})` : ''}}
    </div>
  </div>
</template>

<script>
export default {
  props: ['info', 'title'],
  data() {
    return {
      
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.navigation {
  display: flex;
  align-items: center;
  height: 44px;
  position: relative;
  .navigation-title {
    flex: 1;
    p {
      text-align: center;
      font-size: 16px;
    }
  }
  .right-btn {
    position: absolute;
    right: 16px;
    font-size: 14px;
    color: #666666;
    font-weight: 500;
  }
}
</style>
