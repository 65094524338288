<template>
  <div class="order-detail">
    <div class="detail-info">
      <div class="info" @click="$emit('orderDetail')">
        <p class="name">订单信息</p>
        <p class="money">订单预估金额{{orderDetail.estimated_money ? orderDetail.estimated_money.toFixed(2) : 0}}</p>
        <img src="@/assets/jiantou-right.png" alt="">
      </div>
      <div class="info-location">
        <div class="location">
          <img src="@/assets/zhuang.png" alt="">
          <p>{{ orderDetail.point_name}}</p>
        </div>
        <p class="address">{{ orderDetail.point_address}}</p>
        <div class="location">
          <img src="@/assets/xie.png" alt="">
          <p>{{ orderDetail.uninstall_name }}</p>
        </div>
        <p class="address">{{  orderDetail.uninstall_address }}</p>
      </div>
      <div class="info-code">
        <div class="code-number">
          <p class="name">订单编号</p>
          <p class="number">{{  orderDetail.order_no }}</p>
          <p class="copy" @click="handleCopy(orderDetail.order_no)">复制</p>
        </div>
        <div class="code-number">
          <p class="name">订单时间</p>
          <p class="number">{{ orderDetail.created_at }}</p>
          <!-- <p class="time">复制</p> -->
        </div>
        <div class="code-number">
          <p class="name">订单车型</p>
          <p class="number">{{ orderDetail.device_model_name}}</p>
        </div>
        <div class="code-number">
          <p class="name">货物类型</p>
          <p class="number">{{ orderDetail.goods_type_name }}</p>
        </div>
      </div>
      <div class="btn" @click="$emit('anotherOrder')" style="cursor: pointer">
        <img src="@/assets/zengjia.png" alt="">
        <p >再来一单</p>
      </div>
    </div>
    <div class="detail-price">
       <p class="name" style="paddingBottom: 15px;">已支付费用</p>
      <div class="pay">
        <div class="info" style="padding:0; height: 40px;">
          <p class="name">预付款</p>
          <p class="money">{{orderDetail.prepaid_pay_money ? orderDetail.prepaid_pay_money.toFixed(2) : 0}}元</p>
        </div>
        <div class="code-number">
          <p class="name">付款方式</p>
          <p class="number">{{ orderDetail.pay_type_name }}</p>
        </div>
      </div>
      <!-- <div class="price-info"> 
        <p >费用明细</p>
      </div> -->
    </div>  
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: ['detail'],
  data() {
    return {
      price: 10,
      orderDetail:this.detail
    }
  },
  created() {
    this.orderDetail.created_at = moment.unix(this.orderDetail.create_time).format('YYYY年MM月DD日 HH:mm')
  },
  mounted() {

  },
  methods: {
    handleCopy(val) {
      let input = document.createElement('input') 
      //给input的内容复制
      input.value = val
      // 在body里面插入这个元素
      document.body.appendChild(input)   
      // 选中input里面内容
      input.select()  
      //执行document里面的复制方法
      document.execCommand("Copy") 
      // 复制之后移除这个元素
      document.body.removeChild(input)
      this.$toast('复制成功')
    }
  }
}
</script>

<style scoped lang="scss">
.order-detail {
  border-radius: 8px;
  .info {
      height: 50px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      .name {
        font-size: 16px;
      }
      .money {
        font-size: 24px;
        font-size: 12px;
        flex: 1;
        text-align: right;
        padding-right: 8px;
      }
      img {
        width: 12px;
        height: 12px;
      }
    }
    .code-number {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        color: #999999;
        height: 30px;
        line-height: 30px;
      }
      .name {
      }
      .number {
        flex: 1;
        color: #333;
        text-align: right;
      }
      .time {
        padding-left: 8px;
        color: #333;
      }
      .copy {
        color: #EE690D;
        padding-left: 8px;
      }
    }
  .detail-info {
    background: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .info-location {
      padding: 10px 0;
      margin: 0 15px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      .location {
        display: flex;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
        }
        p {
          flex: 1;
          font-size: 12px;
          color: #666666;
          padding-left: 8px;
        }
      }
      .address {
        padding-left: 25px;
        font-size: 10px;
        color: #999999;
        height: 30px;
        display: flex;
        align-items: center;
        line-height: 12px;
      }
    }
    .info-code {
      padding: 10px 15px;
      border-bottom: 1px solid #eee;
    }
    .btn {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: #666666;
        font-size: 12px;
      }
      img {
        width: 12px;
        height: 12px;
        padding-right: 8px;
      }
    }
  }
  .detail-price {
    background: white;
    border-radius: 8px;
    padding: 15px 15px 8px;
    .pay {
      // border-bottom: 1px solid #eee;
      border-top: 1px solid #eee;
      padding: 4px 0;
    }
    .price-info {
      display: flex;
      justify-content: flex-end;
      p {
        height: 40px;
        line-height: 40px;
        color: #EE690D;
        text-align: right;
        min-width: 100px;
      }
    }
  }
}
</style>
