<template>
  <div class="order-out-in" @click="$emit('clickArrow')">
    <div class="out-in-content">
      <img :src="imgUrl" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: ['isUp'],
  data() {
    return {

    }
  },
  computed: {
    imgUrl() {
      return this.isUp ? require('@/assets/toTop.png') : require('@/assets/bottom.png')
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.order-out-in {
  background: white;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 40px;
  // box-sizing: border-box;
  padding-top: 60px;
  background: transparent;
  img {
    width: 25px;
    height: 25px;
  }
  .out-in-content {
    width: 100%;
    background: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
</style>
