<template>
  <div class="driver-container going-shipment">
    <div class="driver-top" :style="{top: paddingTopValue + 'px'}">
      <Navigation title="到达装货点" :info="info.messageObj"
                  @clickItinerary="$emit('actionClick', eventModel.ITINERARY_MESSAGE)"></Navigation>
    </div>
    <div class="driver-location driver-travel" style="bottom: 74px;">
      <Travel :info="order" @clickMessage="$emit('actionClick', eventModel.SEND_MESSAGE)"  @clickPhone="$emit('actionClick', eventModel.CALL_PHONE)"></Travel>
    </div>
    <div class="driver-bottom">
      <SliderSwipe slideBeforeColor="#EE690D" slideAfterColor="#2CA568" @slidFinish="handleFinish" ref="slidSwipe">
        <p class="slot-name">到达装货点</p>
      </SliderSwipe>
    </div>
  </div>
</template>

<script>
import {EVENT_MODEL} from "../../model/stateModel"
import SliderSwipe from "../slider/slider-swipe.vue"
import Navigation from "./navigation2/index.vue"
import Travel from "./orderInfo/travel.vue"
export default {
  props: ['info'],
  components: {SliderSwipe, Navigation, Travel},
  data() {
    return {
      eventModel: EVENT_MODEL,
      order:this.info.order,
      paddingTopValue: 0
    }
  },
  created() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.paddingTopValue = 0
    } else {
      this.paddingTopValue = 28
    }
  },
  mounted() {

  },
  methods: {
    handleFinish() {
      this.$emit('sureClick', 13)
    },
    handleError() {
      this.$refs.slidSwipe.init()
    }
  }
}
</script>

<style scoped lang="scss">
.going-shipment {
  .driver-bottom {
    display: flex;
    box-sizing: border-box;
  }
  .driver-travel {
    padding: 0;
  }
}
</style>
