// 基础的接口请求汇总
import flyrequest from '@/common/js/requestfly.js'



// 司机端出车
function startWork(data) {
    return flyrequest.fly.request('/device/startWork',data)
}


// 司机端休息
function startRest(data) {
    return flyrequest.fly.request('/device/startRest',data)
}



export default {
    startWork,
    startRest,
}