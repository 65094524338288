<template>
  <div class="test">
    <div style="width: 300px; ">
      <SliderSwipe>
        <slot>
          <p class="slot-name">前往卸货点</p>
        </slot>
      </SliderSwipe>
    </div>
  </div>
</template>
<script>
import SliderSwipe from "../components/slider/slider-swipe.vue"
export default {
  components: {SliderSwipe},
  data() {
    return {
      timer: null,
      durationTime: 0,
      isFinish: false
    }
  },
  created() {
    // this.$nextTick(() => {
    //   this.$refs.swipe.swipeTo(1)
    // })
  },
  mounted() {
    // this.timer = setTimeout(() => {
    //   this.durationTime = 500
    // }, 100);
  },
  // beforeDestroy() {
  //   clearTimeout(this.timer)
  // },
  methods: {
    // handleChange(index) {
    //   if (index == 0) {
    //     // 完成
    //     this.isFinish = true
    //     this.$emit("slidFinish")
    //   }
    //   console.log(index)
    // }
  }
}
</script>

<style scoped lang="scss">
.test {
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

</style>
