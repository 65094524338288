import defaultconfig from "@/common/config/base.js"
import basejs from '@/common/js/base.js'
import store from "@/store";
//import Bridge from '@/common/js/bridge.js'

let fly
let flyGet

// 根据不同平台引入不同的fly库
let Fly

Fly = require("flyio/dist/npm/fly")


fly = new Fly

flyGet = new Fly


//fly的基础配置

// 配置请求根域名
fly.config.baseURL = defaultconfig.baseUrl()
fly.config.timeout = 60000
fly.config.method = 'POST'
//fly.config.withCredentials = true


flyGet.config.baseURL = defaultconfig.baseUrl()
flyGet.config.timeout = 60000
flyGet.config.method = 'GET'


// 拦截请求参数然后对其进行参数包装
function makeParamsForRequest(request) {
    // 添加content-type
    if (!request.headers) request.headers = {}

    request.headers['Content-Type'] = 'application/json'

    request.headers['tickets'] = store.state.$tickets;
    request.headers['v'] = store.state.$v;


    // 添加必传参数的数据
    request.body = basejs.getRequestBaseParam(request.body)


    return request
}

// 配置请求拦截器
/*
请求拦截器中的request对象结构如下：
	baseURL,  //请求的基地址
	body, //请求的参数
	headers, //自定义的请求头
	method, // 请求方法
	timeout, //本次请求的超时时间
	url, // 本次请求的地址
	withCredentials //跨域请求是否发送第三方cookie
*/
let requestInterceptor = function (request) { //不要使用箭头函数，否则调用this.lock()时，this指向不对

    // console.log(`本次接口请求上送的数据为:\n${JSON.stringify(request)}`)

    request = makeParamsForRequest(request)
    return request // 显式返回包装过的request
}

fly.interceptors.request.use(requestInterceptor)
flyGet.interceptors.request.use(requestInterceptor)


// 配置响应拦截器
/*
响应拦截器中的response对象结构如下
	data, //服务器返回的数据
	engine, //请求使用的http engine(见下面文档),浏览器中为本次请求的XMLHttpRequest对象
	headers, //响应头信息
	request  //本次响应对应的请求信息
*/
let responseInterceptor = function (response) {

    // console.log(`本次接口返回的状态为:\n${JSON.stringify(response.data.msg)}`)

    return response.data
}

fly.interceptors.response.use(responseInterceptor)
flyGet.interceptors.response.use(responseInterceptor)


export default {
    fly: fly,// 正常接口的请求实例
    flyGet: flyGet
}
