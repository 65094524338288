// 应用的基础信息

//根据不同环境选择不同的接口请求前缀映射
const baseUrl = function() {
	return "https://jx.hoopoeiot.cn/hoepaas/"
}


export default {
	baseUrl: baseUrl, // 应用接口请求的前缀,
}
