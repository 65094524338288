<template>
  <div class="order-action-item">
    <div class="item" v-for="(item, index) in list" :key="index" :class="index > 0 ? 'item-split' : ''" @click="handleItem(item)">
      <div class="item-icon">
        <img :src="item.icon">
        <div class="message-count" v-if="item.value === 3 && unReadNum>0">{{ unReadNum }}</div>
      </div>
      <p>{{ item.name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list','unReadNum'],
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleItem(item) {
      this.$emit('clickItem', item)
    }
  }
}
</script>

<style scoped lang="scss">
.item-icon {
  position: relative;
}
.order-action-item {
  border-top: 1px solid #EEEEEE;
  width: 100%;
  display: flex;
  flex-direction: row;
  .item-split {
    border-left: 1px solid #EEEEEE;;
  }
  .item {
    
    margin-top: 12px;
    flex: 1;
    img {
      padding-top: 5px;
      width: 16px;
      height: 16px;
    }
    p {
      padding-top: 5px;
      text-align: center;
      color: #666666;
      height: 20px;
      line-height: 20px;
    }
  }

}

</style>
