import { render, staticRenderFns } from "./btn-type.vue?vue&type=template&id=22e431c8&scoped=true&"
import script from "./btn-type.vue?vue&type=script&lang=js&"
export * from "./btn-type.vue?vue&type=script&lang=js&"
import style0 from "./btn-type.vue?vue&type=style&index=0&id=22e431c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e431c8",
  null
  
)

export default component.exports