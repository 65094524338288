<template>
  <div class="map-float-content finish-order">
    <div class="content-bottom order-content">
      <div class="order-des">已支付</div>
      <div class="order-price">
        <p>{{ info.final_payment_money.toFixed(2) }}<span>元</span></p>
      </div>
      <div class="order-btn" @click="handleOrder">查看订单</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleOrder() {
      this.$emit('sureClick', 5)
    }
  }
}
</script>

<style scoped lang="scss">
.finish-order {
  padding: 0;
  .order-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .order-des {
      padding-top: 5px;
      height: 22px;
      line-height: 22px;
      color: #666666;
      font-size: 16px;
    }
    .order-price {
      display: flex;
      align-items: flex-end;
      padding: 10px 0 20px 0;
      p {
        font-weight: bold;
        color: #333333;
        height: 42px;
        line-height: 42px;
        font-size: 36px;
      }
      span {
        color: #333333;
        font-weight: 500;
        line-height: 22px;
        height: 22px;
        font-size: 16px;
      }
    }
    .order-btn {
      margin-bottom: 10px;
      width: 90px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #DDDDDD;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
    }
  }
}
</style>
