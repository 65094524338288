<template>
  <div class="guide-container">
    <div class="guide-info">
      <div class="info-location">
        <p class="location">{{ info.order_status <8 ?info.point_name :info.uninstall_name }}</p>
        <p class="mileage">{{`全程${info.total_point_distance}公里  ${info.total_point_time}分钟`}}</p>
      </div>
     <div class="info-flag" @click="$emit('clickGuide')">
       <img src="@/assets/dingwei.png" alt="">
       <p>开始导航</p>
     </div>
    </div>
    <div class="guide-time" v-if="info && info.mapState === 'GOING_SHIPMENT'">
      <div class="time-line"></div>
      <p>请在<span>{{ info.arriveDate }}</span>分前到达装车点</p>
    </div>
  </div>
</template>

<script>
import basejs from '@/common/js/base.js'
import moment from "moment"
export default {
  props: ['info'],
  data() {
    return {

    }
  },
  computed: {

  },
  created() {
    this.info.arriveDate = `${basejs.getTimeDesc(this.info.car_time)} ${moment(parseInt(this.info.car_time)* 1000).format('HH:mm')}`
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.guide-container {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 0 16px;
  .guide-info {
    display: flex;
    flex-direction: row;
    padding: 4px 0 16px;
    .info-location {
      display: flex;
      flex-direction: column;
      width: calc(100% - 65px);
      .location {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
        color: #333333;

      }
      .mileage {
        padding-top: 5px;
        color: #999999;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .info-flag {
      display: flex;
      flex-direction: column;
      width: 65px;
      justify-content: center;
      img {
        height: 34px;
        object-fit: contain;
      }
      p {
        color: #EE690D;
        font-size: 14px;
        text-align: center;
        padding-top: 8px;
      }
    }
  }
  .guide-time {
    display: flex;
    flex-direction: column;
    position: relative;
    .time-line {
      position: absolute;
      left: -16px;
      right: -16px;
      background: #eee;
      height: 1px;
    }
    p {
      padding: 12px 0;
      font-size: 16px;
    }
    span {
      color: #EE690D;
    }
  }
}
</style>
