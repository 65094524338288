<template>
  <div class="map-float-content">

    <div class="content-bottom" @click="$emit('sureClick', 1)"  v-if="isUp">
      <OrderInfoPrice :price='info.estimated_money'></OrderInfoPrice>
    </div>
    <div v-else>
      <OrderDetail :detail="info" @anotherOrder="addOrder" @orderDetail="$emit('sureClick', 5)"></OrderDetail>
    </div>
    <div class="content-top top-driver">
      <OrderStateDes title="司机正在赶来">
        <slot>
          <span>司机正在前往<span class="span-orange">{{ info.point_name }}</span>途中，请提前到装货点等候</span>
        </slot>
      </OrderStateDes>
      <DriverInfo :detail="info"></DriverInfo>
      <OrderActionItem :list="actionList"  :unReadNum="messageNumber" :detail="info" @clickItem="handleItem"></OrderActionItem>
    </div>
    <OrderOutIn :isUp="isUp" @clickArrow="handleArrow"></OrderOutIn>
  </div>
</template>

<script>
import {ORDER_ACTION_LIST1} from "../model/stateModel"
import OrderInfoPrice from './orderInfo/order-info-price.vue'
import OrderActionItem from './orderInfo/order-action-item.vue'
import DriverInfo from './orderInfo/driver-info.vue'
import OrderStateDes from './orderInfo/order-state-des.vue'
import OrderOutIn from './orderInfo/order-out-in.vue'
import OrderDetail from './orderInfo/order-detail.vue'
export default {
  props: ['info','messageNumber'],
  components: {OrderInfoPrice, OrderActionItem, DriverInfo, OrderStateDes, OrderOutIn, OrderDetail},
  data() {
    return {
      isUp: true,
      actionList: ORDER_ACTION_LIST1,
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleArrow() {
      this.isUp = !this.isUp
    },
    handleItem(val) {
      this.$emit('sureClick', 3, val)
    },
    addOrder(){
      this.$emit('sureClick', 3, {value:7})
    }
  }
}
</script>

<style scoped lang="scss">
.top-driver {
  padding: 15px 0;
}

</style>
