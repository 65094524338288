import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as uni from './utils/uni.webview.1.5.2'; 
import Message from './components/message/index';
import { Swipe, SwipeItem, Toast } from 'vant';

Vue.config.productionTip = false;

document.addEventListener("UniAppJSBridgeReady", function() {  
  Vue.prototype.myUni = uni  
});

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.use(Message)

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Toast)

import api from '@/api/index.js'
Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
