// 基础的接口请求汇总
import flyrequest from '@/common/js/requestfly.js'



// 获取订单详情
function getOrderDetail(data) {
    return flyrequest.fly.request('/order/getOrderDetail',data)
}


// 获取订单详情 - 轨迹信息
function getOrderPolyline(data) {
    return flyrequest.fly.request('/map/getOrderPolyline',data)
}


//查询订单的车辆实时位置
function getCarLoc(data) {
    return flyrequest.fly.request('/map/getCarLoc', data)
}


//司机端修改订单状态
function driverUpdateOrderStatus(data) {
    return flyrequest.fly.request('/order/driverUpdateOrderStatus', data)
}

//司机订单列表
function driverOrderShow(data) {
    return flyrequest.fly.request('/order/driverOrderShow', data)
}

// 修改终点
function updateEndDestination(data) {
  return flyrequest.fly.request('/order/updateDestination', data)
}

//获取预估价格
function getEstimatedMoney(data) {
    return flyrequest.fly.request('/order/getEstimatedMoney', data)
}

//获取我的行程
function myItineraryShow(data) {
    return flyrequest.fly.request('/order/myItineraryShow', data)
}

// 获取周边数据
function getPlace(data) {
  return flyrequest.fly.request('/map/getAround', data)
}
export default {
    getOrderDetail,
    getOrderPolyline,
    getCarLoc,
    driverUpdateOrderStatus,
    driverOrderShow,
    updateEndDestination,
    getEstimatedMoney,
    myItineraryShow,
    getPlace,
}