<template>
  <div class="driver-container going-shipment">
    <div class="driver-top" :style="{top: paddingTopValue + 'px'}">
      <Navigation title="当前行程" :info="info.messageObj"
                  @clickItinerary="$emit('actionClick', eventModel.ITINERARY_MESSAGE)"></Navigation>
    </div>
    <div class="driver-guide" :style="{top: 54 + paddingTopValue + 'px'}">
      <Guide :info="order" @clickGuide="$emit('actionClick', eventModel.BEGIN_GUIDE)"></Guide>
    </div>
    <div class="driver-location">
      <Location :info="order" @clickMessage="$emit('actionClick', eventModel.SEND_MESSAGE)"
                @clickPhone="$emit('actionClick', eventModel.CALL_PHONE)"></Location>
    </div>
    <div class="driver-bottom">
      <div class="bottom-xiuxi" @click="$emit('actionClick', eventModel.XIUXI_CAR)">休息</div>
      <div class="slid">
        <SliderSwipe :state="state" @slidFinish="handleFinish" ref="slidSwipe">
          <p class="slot-name">前往装货点</p>
        </SliderSwipe>
      </div>
    </div>
  </div>
</template>

<script>
import {EVENT_MODEL} from "../../model/stateModel"
import Navigation from "./navigation2/index.vue"
import SliderSwipe from "../slider/slider-swipe.vue"
import Location from "./orderInfo/location.vue"
import Guide from "./orderInfo/guide.vue"

export default {
  props: ['info'],
  components: {SliderSwipe, Navigation, Location, Guide},
  data() {
    return {
      eventModel: EVENT_MODEL,
      order: this.info.order,
      paddingTopValue: 0,
    }
  },
  computed: {
    state() {
      return (this.info && this.info.mapState) ? this.info.mapState : ''
    }
  },
  created() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.paddingTopValue = 0
    } else {
      this.paddingTopValue = 28
    }
  },
  mounted() {

  },
  methods: {
    handleFinish() {
      this.$emit('sureClick', 12)
    },
    handleError() {
      this.$refs.slidSwipe.init()
    }
  }
}
</script>

<style scoped lang="scss">
.going-shipment {
  .driver-bottom {
    padding: 8px;
    display: flex;
    box-sizing: border-box;

    .bottom-xiuxi {
      width: 100px;
      height: 64px;
      line-height: 64px;
      margin-right: 10px;
      box-sizing: border-box;
      background: linear-gradient(270deg, #4C5574 0%, #3D415A 100%);
      color: white;
      border-radius: 8px;
    }

    .slid {
      width: calc(100% - 110px);
    }
  }
}
</style>
