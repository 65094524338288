<template>
  <div class="order-state-des">
    <p class="des-title">{{title}}</p>
    <div class="des-slot">
      <slot></slot>
    </div>
    <div class="state-des-tips" v-if="showTips">
      <img src="@/assets/tingche.png" alt="">
      <span class="tips-content">装、卸点停车，等待<span class="tips-minute">30分钟</span>，超时将会产生额外费用</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'showTips'],
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.order-state-des {
  padding: 0 15px;
  .des-title {
    font-size: 20px;
    height: 28px;
    line-height: 28px;
    font-weight: 600;
  }
  .des-slot {
    padding: 5px 0 10px 0;
    span {
      width: 100%;
      font-size: 12px;
      color: #999999;
      line-height: 20px;
      font-weight: 500;
      text-align: left;
      display: inline-block;
    }
    .span-orange {
      color: #EE690D;
      width: auto;
    }
  }
  .state-des-tips {
    background: #FFF4EC;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 15px;
    box-sizing: border-box;
    img {
      width: 20px;
      height: 20px;
    }
    .tips-content {
      padding-left: 5px;
      font-size: 12px;
      font-weight: 500;
      color: #333333;
    }
    .tips-minute {
      color: #EE690D;
    }
  }
}

</style>
