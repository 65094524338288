<template>
  <div class="map-float-content">
    <div class="content-bottom" @click="$emit('sureClick', 1)" v-if="isUp">
      <OrderInfoPrice :price='info.estimated_money'></OrderInfoPrice>
    </div>
    <div v-else>
      <OrderDetail :detail="info" @anotherOrder="addOrder" @orderDetail="$emit('sureClick', 5)"></OrderDetail>
    </div>
    <div class="content-top top-driver">
      <OrderStateDes title="司机已接单">
        <slot>
          <span>司机会在<span class="span-orange">{{ arriveDate }}</span>准时到达，请您提前准备好货物，出发前30分钟可查看司机位置</span>
        </slot>
      </OrderStateDes>
      <DriverInfo :detail="info"></DriverInfo>
      <OrderActionItem :list="actionList"  :unReadNum="messageNumber" @clickItem="handleItem"></OrderActionItem>
    </div>
    <OrderOutIn :isUp="isUp" @clickArrow="handleArrow"></OrderOutIn>
  </div>
</template>

<script>
import basejs from '@/common/js/base.js'
import {ORDER_ACTION_LIST1} from "../model/stateModel"
import OrderInfoPrice from './orderInfo/order-info-price.vue'
import OrderActionItem from './orderInfo/order-action-item.vue'
import DriverInfo from './orderInfo/driver-info.vue'
import OrderStateDes from './orderInfo/order-state-des.vue'
import OrderDetail from './orderInfo/order-detail.vue'
import OrderOutIn from './orderInfo/order-out-in.vue'
import moment from "moment";

export default {
  props: ['info', 'messageNumber'],
  components: {OrderInfoPrice, OrderActionItem, DriverInfo, OrderStateDes, OrderDetail, OrderOutIn},
  data() {
    return {
      actionList: ORDER_ACTION_LIST1,
      isUp: true,
      arriveDate: ''
    }
  },
  created() {
    this.arriveDate = `${basejs.getTimeDesc(this.info.car_time)} ${moment(parseInt(this.info.car_time) * 1000).format('HH:mm')}`
  },
  mounted() {

  },
  methods: {
    handleArrow() {
      this.isUp = !this.isUp
    },
    handleItem(val) {
      this.$emit('sureClick', 3, val)
    },
    addOrder() {
      this.$emit('sureClick', 3, {value: 7})
    }
  }
}
</script>

<style scoped lang="scss">
.top-driver {
  padding: 15px 0;
}

</style>
