<template>
  <div class="alter-order-end-location">
    <div class="alter-order-background"></div>
    <div class="alter-order-bottom">
      <img src="@/assets/btn_guanbi.png" @click="handleClose">
      <OrderStateDes :title="`全程预估约 ${price} 元`">
        <slot>
          <!-- {{location}} -->
          <span class="end-location">终点将修改为<span class="span-orange">{{ location.address }}</span></span>
        </slot>
      </OrderStateDes>
      <p class="tip-des"><span class="tip-dot"></span>本次行程将按实际里程计费</p>
      <p class="tip-des"><span class="tip-dot"></span>请提醒司机更新导航</p>
      <div class="btn-groups">
        <div class="btn btn-cancel" @click="handleCancel">取消</div>
        <div class="btn btn-sure" @click="handleSure">确认修改</div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderStateDes from './order-state-des.vue'

export default {
  props: ['location', 'detail'],
  components: {OrderStateDes},
  data() {
    return {
      price: 0,
    }
  },
  created() {

  },
  mounted() {
    this.getEstimatedPrice()
  },
  methods: {
    //获取预估价格
    getEstimatedPrice() {
      const params = {
        order_no: this.detail.order_no,
        device_model_id: this.detail.device_model_id,
        goods_type_id: this.detail.goods_type_id,
        point_name: this.detail.point_name,
        point_address: this.detail.point_address,
        point_longitude: this.detail.point_longitude,
        point_latitude: this.detail.point_latitude,
        uninstall_name: this.location.address ? this.location.address : '',
        uninstall_address: this.location.fullAddress ? this.location.fullAddress : '',
        uninstall_longitude: this.location.location.lng ? this.location.location.lng : '',
        uninstall_latitude: this.location.location.lat ? this.location.location.lat : '',
      }
      console.log('预估价格', params)
      this.$api.order.getEstimatedMoney(params).then(res => {
        console.log('res===', res)
        if (res.ret === 0) {
          this.price = res.data.estimated_money
        }
      }).catch(error => {

      })
    },
    handleClose() {
      this.$emit('clickCancel')
    },
    handleCancel() {
      this.$emit('clickCancel')
    },
    handleSure() {
      this.$emit('clickSure')
    }
  }
}
</script>

<style scoped lang="scss">
.alter-order-end-location {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .alter-order-bottom {
    img {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 16px;
      height: 16px;
    }

    .end-location {
      font-size: 14px;
      color: #666666;
    }
  }

  .tip-des {
    padding: 0 15px;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    line-height: 20px;
    display: flex;
    align-items: center;

    .tip-dot {
      background: #666666;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      display: inline-block;
      margin-right: 8px;
    }
  }

  .btn-groups {
    display: flex;
    flex-direction: row;
    padding: 25px 15px 0px 15px;

    .btn {
      height: 48px;
      line-height: 48px;
      flex: 1;
      border: 1px solid #DDDDDD;
      border-radius: 8px;
      font-weight: 500;
    }

    .btn-cancel {
      margin-right: 8px;
      color: #666666;
    }

    .btn-sure {
      margin-left: 8px;
      background: #EE690D;
      color: #FFF;
    }
  }
}
</style>
