<template>
  <div class="driver-container going-shipment">
    <div class="driver-top" :style="{top: paddingTopValue + 'px'}">
      <Navigation title="等待装货" :info="info.messageObj" @clickItinerary="$emit('actionClick', eventModel.ITINERARY_MESSAGE)"></Navigation>
    </div>
    <div class="driver-des" :style="{top: 44 + paddingTopValue + 'px'}">
      <p>已到达装货地点，请等待客户装货</p>
    </div>
    <div class="driver-guide" :style="{top: 98 + paddingTopValue + 'px'}">
      <Guide :info="order" @clickGuide="$emit('actionClick', eventModel.BEGIN_GUIDE)"></Guide>
    </div>
    <div class="driver-location" style="bottom: 74px;">
      <Location :info="order" @clickMessage="$emit('actionClick', eventModel.SEND_MESSAGE)" @clickPhone="$emit('actionClick', eventModel.CALL_PHONE)"></Location>
    </div>
    <div class="driver-bottom">
      <SliderSwipe @slidFinish="handleFinish" ref="slidSwipe">
        <p class="slot-name">前往卸货点</p>
      </SliderSwipe>
    </div>
  </div>
</template>

<script>
import SliderSwipe from "../slider/slider-swipe.vue"
import Navigation from "./navigation2/index.vue"
import Location from "./orderInfo/location.vue"
import {EVENT_MODEL} from "../../model/stateModel"
import Guide from "./orderInfo/guide.vue"
export default {
  props: ['info'],
  components: {SliderSwipe, Navigation, Location, Guide},
  data() {
    return {
      eventModel: EVENT_MODEL,
      order:this.info.order,
      paddingTopValue: 0
    }
  },
  created() {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.paddingTopValue = 0
    } else {
      this.paddingTopValue = 28
    }
  },
  mounted() {

  },
  methods: {
    handleFinish() {
      this.$emit('sureClick', 14)
    },
    handleError() {
      this.$refs.slidSwipe.init()
    }
  }
}
</script>

<style scoped lang="scss">
.going-shipment {
  .driver-bottom {
    display: flex;
    box-sizing: border-box;
  }
  .driver-des {
    position: fixed;
    top: 44px;
    left: 0px;
    right: 0px;
    background: white;
    height: 44px;
    border-top: 1px solid #eee;
    line-height: 44px;
    p {
      padding: 0 16px;
      color: #666666;
      font-size: 14px;
    }
  }
}
</style>
