import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ChooseLocation from "../views/ChooseLocation.vue";
import Test from "../views/Test.vue"
import Driver from "../views/driver.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: '/customer',
  },
  {
    path: "/customer",
    name: "Customer",
    component: Home,
  },
  {
    path: "/chooseLocation",
    name: "chooseLocation",
    component: ChooseLocation,
  },
  {
    path: "/driver",
    name: "Driver",
    component: Driver,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
